@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500&display=swap');

:root{
  --header-height: 5rem;

  /* color */
  --button-color: #7A5AF8;
  --button-color-alt: #121212;
  --text-color:  #fff;
  --text-color-alt: #7A5AF8;
  --text-color-light: #BDB4FE;
  --text-color-lighter: #f3f3f4;
  --background-color: #181620;
  --container-color: #4b5563;
  --border-color: hsl(0,0%,94%);

  /* font */
  --body-font: 'Inter';
  --header-font: 'Gilroy';
  --h1-font-size: 4.5rem;
  --h2-font-size: 3.25rem;
  --normal-font-size: 1rem;
  --num-grid: 12;
  

  /* font weight */
  --font-medium: 500;
  --font-bold: 700;

  /* z index */
  --z-tooltip: 10;
  --z-fixed: 100;

}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-FREE/Gilroy-ExtraBold.otf') format('truetype');
  font-weight: 700;
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html{
  scroll-behavior: smooth;
}

body,button,input{
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body{
  margin: 0;
}

h1,h2{
  color: var(--text-color);
  font-family: var(--header-font);
}
h1{
  font-size: var(--h1-font-size);
}
h2{
  font-size: var(--h2-font-size);
}

ul{
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

img{
  max-width: 100%;
  height: auto;
}

button{
  cursor: pointer;
  border: none;
  outline: none;
}
.btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  font-weight: var(--font-medium);
  gap: 8px;
  background: var(--button-color);
  border-radius: 4px;   
  width: 228px;
  height: 48px;
}
.container, .crypto-container{
  margin: 0 auto;
  width: 100%;
  max-width: 1260px;
  padding: 0 1rem;
}
.nav{
  background-color: var(--background-color);
  border-bottom: 1px solid var(--button-color);
  backdrop-filter: blur(20px);
  height: var(--header-height);
  z-index: 10;
  position: relative;
}
.nav-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
  gap: 3rem;
}
.nav-details{
  position: absolute;
  top: var(--header-height);
  right: 0;
  background-color: var(--background-color);
  border-radius: 4px;
  max-width: 450px;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  margin: 1rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: .3s;
  row-gap: 1rem;
}
.hide-details{
  top: var(--header-height);
  right: -200%;
  position: absolute;
  background-color: var(--background-color);
  border-radius: 4px;
  max-width: 450px;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  margin: 1rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: .3s;
  row-gap: 1rem;
}
.nav-texts{
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.nav-texts > h1{
  font-size: 32px;
  line-height: 32px;
}
.nav-span{
  display: inline-flex;
  align-items: center;
  padding: 0 24px;
  background-color:#EBE9FE;
  border-radius: 4px;
  width: fit-content;
}
.nav-form{
 display: flex;
 flex-direction: column;
 row-gap: 1rem;
 width: inherit;
}
.nav-form > input{
  padding-left: 1rem;
  background-color: var(--background-color);
  outline: none;
  border: none;
  border-radius: 4px;
  color: var(--text-color-lighter);
  border: 1px solid var(--button-color);
  height: 48px;
}
.nav-form> button{
  width: inherit;
}
.hero{
  background-color: var(--background-color);
  color: var(--text-color);
  height: 100vh;
  background-image:   url('./image/Stallet\ Pattterns\ 1.png');
  background-size: cover;
  background-position: center;
  width: 100%;
}
.hero-container{
  display: grid;
  grid-template-columns: repeat(var(--num-grid), 1fr);
  gap: 20px;
  height: inherit;
  align-items: center;
}
.hero-details{
  grid-column: 2/span 10;
  text-align: center;
}
.hero-texts{
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.hero-button{
  display: flex;
  justify-content: center;
}
.hero-button > div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: var(--button-color-alt);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  height: 42px;
  color: var(--text-color);
  text-transform: capitalize;
}
.hero-button > div > span{
  height: inherit;
  display: flex;
  align-items: center;
}
.hero-texts > h1{
  line-height: 76px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}
.hero-light{
  color: var(--text-color-light);
}
.hero-alt{
  color: var(--text-color-alt);
}
.hero-paragraph{
  display: flex;
  justify-content: center;
}
.hero-paragraph > p{
  font-size: 18px;
  line-height: 28px;
  width: 100%;
  max-width: 808px;
  text-align: center;
  color: var(--text-color-lighter);
}
.hero-forms{
  margin: 3rem auto 0;
  display: flex;
  border-radius: 4px;
  width: 100%;
  max-width: 607px;
  border: 1px solid var(--button-color);
}
.hero-forms > input{
  max-width: 379px;
  width: 100%;
  padding-left: 1rem;
  background-color: var(--background-color);
  outline: none;
  border: none;
  border-radius: 4px;
  color: var(--text-color-lighter);
}
::placeholder{
  color: var(--text-color-lighter);
  font-weight: 200;
}
.access{
  display: grid;
  height: 100vh;
  width: 100%;
  grid-template-columns: repeat(var(--num-grid), 1fr);
}
.access-image{
  grid-column: span 6;
  background-color: var(--button-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.access-image > img{
  height: 85%;
}
.access-texts{
  grid-column: 7/span 12;
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  row-gap: 1rem;
  justify-content: center;
}
.access-texts > h2{
  font-size: var(--h2-font-size);
  width: 100%;
  max-width: 500px;
  line-height: 56px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}
.access-paragraph{
  display: flex;
  flex-direction: column;
  row-gap: 1rem;  
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  max-width: 500px;
  color: var(--text-color-lighter);
}
.crypto{
  background-color: var(--background-color);
  padding-top: 100px;
  background-image: url('./image/Stallet\ Pattterns\ 2.png');
  background-size: cover;
  background-position: center;
  /* margin-top: 8.7rem; */
  position: relative;
  width: 100%;
}
.crypto-container{
  margin-top: 53px;
}
.crypto-heading{
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
}
.crypto-heading > h2{
  text-transform: capitalize;
  font-weight: 700;
}
.crypto-heading > p{
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-color-lighter);
}
.crypto-details{
  display: grid;
  grid-template-columns: repeat(var(--num-grid), 1fr);
}
.crypto-image{
  grid-column: span 6;
  background-color: var(--button-color);
  display: flex;
  justify-content: center;
  border-radius: 32px 32px 0px 0px;
  padding-top: 5.75rem;
  padding-left: 5rem;
}
.crypto-features{
  grid-column: 7/span 12;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 580px;
  justify-content: center;
  padding-left: 4rem;
}
.crypto-features-container{
  display: flex;
  column-gap: 1.5rem;
  align-items: center;
  padding: 1.5rem 0;
}
.crypto-features-image{
  height: inherit;
}
.crypto-features-texts{
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
}
.crypto-features-texts > h3{
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  font-weight: 600;
  color: var(--text-color);
}
.crypto-features-texts > p{
  font-size: 16px;
  line-height: 24px;
  color: #E8E7EA;
}
.web3{
  display: grid;
  grid-template-columns: repeat(var(--num-grid), 1fr);
  height: 100vh;
  width: 100%;
}
.web3-texts{
  grid-column: span 6;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  padding-right: 1.25rem;
}
.web3-texts-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 1rem;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
.web3-texts-container > h2{
  line-height: 56px;
  letter-spacing: -0.02em;
  font-weight: 700;
}
.web3-paragraph{
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color-lighter);
  font-weight: 400;
}
.web3-image{
  grid-column: 7/span 12;
  background-color: var(--button-color);
  align-items: center;
  display: flex;
  justify-content: center;
}
.web3-image > img{
  height: 85%;
}
.footer{
  background-color: var(--background-color);
  height: 100vh;
  position: relative;
  width: 100%;
  z-index: 5;
}
.footer-container{
  display: flex;
  flex-direction: column;
  height: inherit;
}
.footer-details{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 3rem;
  max-width: 533px;
  width: 100%;
  margin: 6.25rem auto;
  height: 80%;
}
.footer-texts{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
}
.footer-images{
  width: inherit;
  display: flex;
  flex-wrap: wrap;
}
.footer-texts > h1{
  font-weight: 700;
  line-height: 76px;
  letter-spacing: -0.02em;
}
.footer-span{
 padding: 0 43px;
 line-height: 58px;
 background-color:#EBE9FE;
 border-radius: 4px;
}
.footer-form{
  display: flex;
  width: 100%;
  max-width: 520px;
  border: 1px solid var(--button-color);
  border-radius: 4px;
  justify-content: space-between;
  gap: 0;
}
.footer-form > button{
  max-width: 180px;
}
.footer-form > input{
  background-color: var(--background-color);
  padding-left: 1rem;
  border: none;
  border-radius: 4px;
  outline: none;
  color: var(--text-color-lighter);
  width: inherit;
}
.footer-socials{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-top: 1px solid var(--button-color);
  height: 20%;
}
.footer-socials > p{
  color: var(--text-color-lighter);
}
.footer-links{
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}
.footer-links > li > a{
  color: var(--text-color-lighter);
  font-size: 1.5rem;
}
.confirm-main{
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  height: 100%;
  min-height: 100vh;
  position: relative;
}
.transition{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-color);
  z-index: var(--z-fixed);
}
.transition > img{
  width: 100px;
}
.confetti{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  /* z-index: -1; */
}
.confirm{
  background-image: url('./image/Stallet\ Pattterns\ 1.png');
  background-size: cover;
  background-position: center;
}
.confirm-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  padding-top: 1.75rem;
}
.confirm-mail{
  display: flex;
  align-items: center;
  column-gap: 1rem;
  color: var(--text-color);
}
.confirm-mail > p{
  font-weight: 700;
  line-height: 24px;
}
.confirm-mail > a{
  text-decoration: underline;
  font-weight: 400;
}
.confirm-body{
  width: 100%;
  max-width: 880px;
  text-align: center;
  margin: 11.25rem auto 8.75rem;
}
.confirm-body-1{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
.confirm-body-1 > h1{
  line-height: 76px;
  letter-spacing: -0.02em;
}
.confirm-body-1 > p{
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-color-lighter);
  width: 100%;
  max-width: 808px;
}
.confirm-body-2{
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.confirm-body-mail{
  display: flex;
  gap: 1rem;
  margin: 3rem auto 0;
}
.confirm-body-mail > p{
  font-weight: 700;
  color: var(--text-color);
}
.confirm-body-mail > a{
  display: flex;
  align-items: center;
  gap: 2px;
  text-decoration: underline;
}
.confirm-body-mail > a >span{
  display: flex;
  align-items: center;
}
.confirm-body-2 > p{
  font-style: italic;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-color);
}
.confirm-body-2 > p > span{
  font-style: normal;
}
.waitlist {
  position: relative;
  overflow: hidden;
}
.waitlist-cover{
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: var(--button-color);
  z-index: var(--z-fixed);
  display: flex;
  align-items: center;
  justify-content: center;
}
.waitlist-cover > img{
  width: 100px;
}
.p3 > span{
  font-weight: 500;
  font-style: italics;
}

@media (max-width: 1260px){
  .container, .crypto-container{
    max-width: 1024px;
  }
  .access-texts{
    padding-left: 50px;
  }
  .access-texts > h2, .access-paragraph{
    max-width: 450px;
  }
  .web3-texts{
    padding-right: 0;
  }
  .web3-texts-container{
    max-width: 450px;
  }
}
@media (max-width: 1024px){
  :root{
    --num-grid: 8;
  }
  .container, .crypto-container{
    max-width: 768px;
  }
  .hero-details{
    grid-column: span 8;
  }
  .access-image, .access-texts{
    grid-column: span 8;
  }
  .access{
    height: auto;
  }
  .access-image{
    order: 2;
    height: 100vh;
    z-index: 3;
  }
  .access-texts{
    justify-content: center;
    padding: 80px 0;
    height: 100vh;
    align-items: center;
    text-align: center;
  }
  .access-texts > h2, .access-paragraph{
    max-width: 70%;
  }
  .crypto{
    padding: 80px 0;
    position: relative;
  }
  .crypto-details{
    width: 100%;
    row-gap: 48px;
  }
  .crypto-image, .crypto-features{
    grid-column: span 8;
  }
  .crypto-features{
    max-width: 100%;
    padding:0 1.25rem;
  }
  .web3{
    height: auto;
  }
  .web3-texts,.web3-image{
    grid-column: span 8;
    height: 100vh;
  }
  /* .web3-image{
  } */
  .web3-texts-container{
    align-items: center;
    text-align: center;
    max-width: 70%;
    margin: 80px auto;
  }
  /* .footer{
    height: auto;
  }
  .footer-details{
    margin: 6.25rem auto;

  } */
}
@media (max-width: 768px){
  :root{
    --header-height: 4.5rem;
    --h1-font-size: 2rem;
    --h2-font-size: 2rem;
  }
  .container{
    max-width: 500px;
    padding: 0 1.25rem;
  }
  .nav-container > button{
    width: 116px;
    height: 40px;
    font-size: 14;
  }
  .nav-container > button > span{
    display: none;
  }
  .nav-details{
    max-width: 350px;
    margin-right: 1.5rem;
  }
  .hero-details{
    grid-column: span 8;
    gap: 2rem;
  }
  .hero-button > div{
    padding: 6px 12px;
    height: 34px;
  }
  .hero-texts > h1{
    line-height: 40px;
  }
  .hero-texts > p{
    font-size: 16px;
    line-height: 24px;
  }
  .hero-forms, .footer-form{
    flex-direction: column;
    gap: 1rem;
    border: none;
  }
  .hero-forms > input, .footer-form > input{
    max-width: 100%;
    border: 1px solid var(--button-color);
    height: 3rem;
  }
  .hero-btn{
    width: 100%;
    height: 3rem;
  }
  .access-texts > h2, .access-paragraph{
    max-width: 80%;
  }
  .access-texts{
    padding: 80px 0;
  }
  .access-texts > h2, .crypto-heading >h2, .web3-texts-container > h2{
    line-height: 40px;
  }
  .access-paragraph{
    font-size: 16px;
    line-height: 24px;
  }
  .crypto-container{
    max-width: 100%;
    padding: 0;
  }
  .crypto-heading > p, .web3-paragraph{
    line-height: 24px;
    font-size: 16px;
  }
  .crypto-features{
    max-width: 100%;
  }
  .crypto-features-container{
    padding: 20px 0px 20px 24px;
    gap: 20px;
  }
  .crypto-features-texts > h3{
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  }
  .crypto-features-texts > p{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .crypto-image{
    padding-top: 61.47px;
  }
  .footer-texts{
    max-width: 100%;
  }
  .footer-texts > h1{
    line-height: 48px;
    font-size: 44px;
  }
  .footer-texts > h1 > span{
    padding: 4px 25px 0px;
  }
  .footer-images, .footer-socials, .footer-form > button{
    max-width: 100%;
  }
  .footer-socials{
    flex-direction: column;
    gap: 1rem;
  }
  .footer-socials > p{
    order: 2;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  .confirm-main{
    min-height: 100vh;
    gap: 4rem;
  }
  .confirm-body-1{
    gap: 1rem;
  }
  .confirm-body-1 > h1{
    font-size: 32px;
    line-height: 40px;
  }
  .confirm-body-1 > p, .confirm-body-2 > p{
    font-size: 16px;
    line-height: 24px;
  }
  .confirm-body-2{
    gap: 2rem;
  }
  .confirm-mail{
    gap: 1rem;
  }
  .confirm-body-mail{
    gap: 1rem;
    margin-top: 2rem;
  }
  .confirm-mail > p, .confirm-mail-link, .confirm-body-mail > p, .confirm-body-mail > a{
    font-size: 14px;
    line-height: 20px;
  }
  .confirm-footer{
    padding-bottom:2rem;
  }
}
@media (max-width: 500px){
  .container{
    max-width: 100%;
  }
  .access-texts > h2, .access-paragraph, .web3-texts-container{
    max-width: 100%;
    padding: 0 1.25rem;
  }
  .nav-details{
    margin-right: 1rem;
  }
  .footer-images > img, .nav-images > img{
    width: 40.78px;
    height: 40px;
    margin: 0px -4px;
  }
  ::placeholder{
    font-size: 12px;
  }
  .confirm-header{
    flex-direction: column;
    align-items: flex-start;
  }
  .confirm-mail{
    align-self: center;
  }
  .footer-socials{
    padding-bottom: 3rem;
  }
}
@media (max-width: 375px){
  .nav-details{
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    overflow: hidden;
    /* margin-left: 1rem; */
  }
  .hero-texts{
    gap: 1rem;
  }
  .hero-forms{
    margin-top: 1rem;
  }
  .crypto-image{
    padding-left: 3.5rem;
  }
  .footer-links{
    column-gap: 1rem;
  }
  .footer-links > li > a{
    font-size: 1rem;
  }
  .confirm-mail, .confirm-body-mail{
    gap: .5rem;
  }
  .footer-details{
    height: 55%;
  }
  .footer-images{
    width: fit-content;
  }
  .footer-socials{
    height: fit-content;
  }
}